// import firebase from 'firebase/app'
// import 'firebase/firestore'

// let firebase = window.app;

// Get a Firestore instance
export const db = window.app.firestore();

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// const { Timestamp, GeoPoint } = firebase.firestore
const { Timestamp, GeoPoint } = window.app.firestore;
export { Timestamp, GeoPoint };

// window.ha.log("Inside DB.js")

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import LabList from "../views/LabList.vue";
import ReportList from "../views/ReportList.vue";

// Patient Report
import PatientReport from "../views/patientReport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home2",
    component: Home,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/labList",
    name: "LabList",
    component: LabList,
  },
  {
    path: "/lab/:lab/reportList",
    name: "ReportList",
    component: ReportList,
  },
  // report details
  {
    path: "/patientReport/org/:orgId/report/:reportId/",
    name: "PatientReport",
    component: PatientReport,
  },
  // report details
  {
    path: "/patientReport/org/:orgId/report/:reportId/emailMode/:emailMode",
    name: "PatientReportEmail",
    component: PatientReport,
  },
];

const router = new VueRouter({
  routes,
});

export default router;

<template>
  <v-row
    no-gutters
    class="ha-test-row black--text"
    v-bind:class="{ haMobile: isMobile }"
    v-bind:style="{
      marginTop: (linespace ? linespace : '0') + 'rem',
    }"
  >
    <!-- No Print - for edit and display - colums are responsive on mobile and desktop -->
    <v-col class="noprint ha-fos" cols="12" v-if="test.type == 'FOS'">
      {{ test.name }}
    </v-col>
    <v-col
      v-else
      class="noprint"
      v-for="(item, index) in columns"
      :key="index"
      cols="12"
      :sm="
        item.id == 'result' &&
        (!test.refRanges || test.refRanges.length == 0) &&
        !test.unit
          ? 12 - parseInt(columns[0].flex)
          : item.flex
      "
    >
      <div v-if="item.id == 'name'" v-bind:class="[levelStyleClass, isGroup]">
        {{ test.name }}
        <div v-if="test.method" style="margin-top: -5px; font-size: 90%">
          <i> Method: {{ test.method }} </i>
        </div>
        <div v-if="test.formula">
          <i> Formula: {{ test.formula }} </i>
        </div>
      </div>
      <div v-if="item.id == 'result'" class="d-inline-flex" style="width: 90%">
        <div
          v-if="mode == 'display'"
          v-bind:class="{ abnormal: test.abnormal == true }"
        >
          <span v-show="isMobile && test.type != 'GROUP'" class="noprint"
            >Result:
          </span>
          {{ test.result }}
        </div>
        <v-text-field
          v-bind:class="{
            abnormal: test.abnormal == true,
            formula: test.formula != undefined && test.formula != '',
          }"
          v-model="test.result"
          @keydown="checkShortcut"
          @keyup="checkAndCalculate"
          dense
          solo
          style="width: 100%"
          hide-details="auto"
          v-if="mode == 'edit' && test.type == 'NORMAL'"
        >
        </v-text-field>

        <!-- <v-tooltip
					right
					v-if="mode == 'edit' && test.type == 'NORMAL' && test.formula"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							v-on="on"
							class="display-inline"
							elevation="0"
							@click="calculate()"
						>
							<span class="material-icons">&#xea5f;</span>
						</v-btn>
					</template>
					<span>Auto-Calculate</span>
				</v-tooltip> -->
      </div>
      <div v-if="item.id == 'refRange'">
        <span v-show="isMobile && test.refRanges && test.refRanges.length > 0"
          >Ref Ranges:
        </span>
        <ul style="list-style: none; padding-left: 0">
          <li
            v-for="(refRange, i) in test.refRanges"
            :key="i"
            v-bind:class="[{ highlight: ishighlighted(refRange) }]"
            @click="highlight(refRange)"
          >
            <div v-if="refRange.min && refRange.max">
              <span v-if="refRange.refName">{{ refRange.refName }} :</span>
              {{ refRange.min }} to {{ refRange.max }}
            </div>
            <div v-if="refRange.min && !refRange.max">
              <span v-if="refRange.refName">{{ refRange.refName }} :</span>
              <span v-if="!isNaN(refRange.min)">></span>
              {{ refRange.min }}
            </div>
            <div v-if="!refRange.min && refRange.max">
              <span v-if="refRange.refName">{{ refRange.refName }} :</span>
              <span v-if="!isNaN(refRange.max)">&#x3c;</span> {{ refRange.max }}
            </div>
          </li>
        </ul>
      </div>
      <div v-if="item.id == 'unit'">
        <span v-show="isMobile && test.unit">Unit: </span>
        {{ test.unit }}
      </div>
    </v-col>

    <!-- Note / impression -->
    <v-col class="noprint" cols="12">
      <div
        v-if="test.note"
        style="margin-top: -5px; margin-bottom: 0rem"
        v-bind:class="[levelStyleClass, isGroup]"
      >
        <i style="font-size: 90%"> {{ test.note }} </i>
      </div>
    </v-col>

    <!-- Print - columns are not responsive -->
    <v-col class="print ha-fos" cols="12" v-if="test.type == 'FOS'">
      {{ test.name }}
    </v-col>
    <v-col
      v-else
      class="print"
      v-for="(item, index) in columns"
      :key="index + '-print'"
      :cols="
        item.id == 'result' &&
        (!test.refRanges || test.refRanges.length == 0) &&
        !test.unit
          ? 12 - parseInt(columns[0].flex)
          : item.flex
      "
    >
      <div v-if="item.id == 'name'" v-bind:class="[levelStyleClass, isGroup]">
        {{ test.name }}
        <div v-if="test.method" style="margin-top: -5px; font-size: 90%">
          <i> Method: {{ test.method }} </i>
        </div>
      </div>
      <div v-if="item.id == 'result'">
        <div
          v-if="mode == 'display'"
          v-bind:class="{ abnormal: test.abnormal == true }"
        >
          <span v-show="isMobile && test.type != 'GROUP'" class="noprint"
            >Result:
          </span>
          {{ test.result }}
        </div>
        <v-text-field
          v-bind:class="{ abnormal: test.abnormal == true }"
          v-model="test.result"
          dense
          solo
          style="width: 10rem"
          v-if="mode == 'edit' && test.type == 'NORMAL'"
        ></v-text-field>
      </div>
      <div v-if="item.id == 'refRange'">
        <span
          class="refRangeLabel"
          v-show="isMobile && test.refRanges && test.refRanges.length > 0"
          >Ref Ranges:
        </span>
        <ul style="list-style: none; padding-left: 0">
          <li
            v-for="(refRange, i) in test.refRanges"
            :key="i"
            v-bind:class="[{ highlight: ishighlighted(refRange) }]"
            @click="highlight(refRange)"
          >
            <div
              v-if="
                refRange.min &&
                refRange.max &&
                (refRange.printableRefRange == 'true' || refRange.highlight)
              "
            >
              <span v-if="refRange.refName">{{ refRange.refName }} :</span>
              {{ refRange.min }} to {{ refRange.max }}
            </div>
            <div
              v-if="
                refRange.min &&
                !refRange.max &&
                (refRange.printableRefRange == 'true' || refRange.highlight)
              "
            >
              <span v-if="refRange.refName">{{ refRange.refName }} :</span>
              <span v-if="!isNaN(refRange.min)">></span> {{ refRange.min }}
            </div>
            <div
              v-if="
                !refRange.min &&
                refRange.max &&
                (refRange.printableRefRange == 'true' || refRange.highlight)
              "
            >
              <span v-if="refRange.refName">{{ refRange.refName }} :</span>
              <span v-if="!isNaN(refRange.max)">&#x3c;</span> {{ refRange.max }}
            </div>
          </li>
        </ul>
      </div>
      <div v-if="item.id == 'unit'">
        <span v-show="isMobile && test.unit">Unit: </span>
        {{ test.unit }}
      </div>
    </v-col>

    <!-- Note / impression -->
    <v-col class="print" cols="12">
      <div
        v-if="test.note"
        style="margin-top: -5px; margin-bottom: 0rem"
        v-bind:class="[levelStyleClass, isGroup]"
      >
        <i style="font-size: 90%"> {{ test.note }} </i>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { evaluate } from "mathjs";
export default {
  name: "TestRow",
  props: {
    test: Object,
    peers: Array,
    level: String,
    mode: String,
    columns: Array,
    linespace: String,
    shortcuts: Array,
  },
  data() {
    return {
      levelStyleClass: "",
      isGroup: "",
      isMobile: window.isMobile(),
      printableRefRange: "true",
      noHighlightedRefRange: false,
    };
  },
  watch: {
    "test.result": {
      handler() {
        this.testResultUpdated();
      },
      deep: true,
    },
  },
  created() {
    this.levelStyleClass = "row-level-" + this.level;
    if (this.test.type == "GROUP") this.isGroup = "ha-test-group";
    this.updatePrintableRefRanges();
    this.noHighlightedRefRange = true;
    for (let refRange of this.test.refRanges) {
      if (refRange.highlight == true) {
        this.noHighlightedRefRange = false;
      }
    }
  },
  methods: {
    updatePrintableRefRanges() {
      let highlightedRefRange = null;
      for (let refRange of this.test.refRanges) {
        if (refRange.highlight == true) {
          highlightedRefRange = refRange;
        }
      }
      if (highlightedRefRange == null) {
        this.printableRefRange = "true";
        for (let refRange of this.test.refRanges) {
          refRange.printableRefRange = "true";
        }
      } else {
        this.printableRefRange = "false";
        for (let refRange of this.test.refRanges) {
          refRange.printableRefRange = "false";
        }
      }
    },
    testResultUpdated() {
      let test = this.test;
      let highlightedRefRange = null;
      this.noHighlightedRefRange = true;
      for (let refRange of test.refRanges) {
        if (refRange.highlight == true) {
          highlightedRefRange = refRange;
          this.noHighlightedRefRange = false;
        }
      }
      if (highlightedRefRange) {
        let result = parseFloat(test.result.replace(",", ""));
        if (!isNaN(result)) {
          let min =
            highlightedRefRange.min != undefined
              ? parseFloat(highlightedRefRange.min.replace(",", ""))
              : undefined;
          let max =
            highlightedRefRange.max != undefined
              ? parseFloat(highlightedRefRange.max.replace(",", ""))
              : undefined;
          if (min && max) {
            if (result >= min && result <= max) {
              test.abnormal = false;
            } else {
              test.abnormal = true;
            }
          } else if (min) {
            if (result >= min) {
              test.abnormal = false;
            } else {
              test.abnormal = true;
            }
          } else if (max) {
            if (result <= max) {
              test.abnormal = false;
            } else {
              test.abnormal = true;
            }
          }
        }
      } else {
        // if it is not a number, compare == with highlighted
        test.abnormal =
          (test.result &&
            highlightedRefRange &&
            highlightedRefRange.min != undefined &&
            highlightedRefRange.min.toUpperCase() !=
              test.result.toUpperCase()) ||
          (test.result &&
            highlightedRefRange &&
            highlightedRefRange.max != undefined &&
            highlightedRefRange.max.toUpperCase() != test.result.toUpperCase());
      }
    },
    ishighlighted(refRange) {
      return refRange.highlight == true;
    },
    highlight(refRange) {
      if (this.mode != "edit") return;

      if (refRange.highlight == true) {
        refRange.highlight = false;
        this.test.abnormal = false;
        this.noHighlightedRefRange = true;
        this.updatePrintableRefRanges();
        return;
      }

      for (let obj of this.test.refRanges) {
        obj.highlight = false;
      }

      refRange.highlight = true;
      this.testResultUpdated();
      this.updatePrintableRefRanges();
    },
    calculate() {
      window.ha.log("Calculating using formula...");
      this.test.result = "";
      // prep the scope
      let scope = {};

      if (this.peers) {
        for (let peer of this.peers) {
          if (peer.code && peer.result) {
            scope[peer.code] = peer.result;
          }
        }
      }

      let result = evaluate(this.test.formula, scope); // 12

      window.ha.log("Result : " + result);
      if (!Number.isInteger(result)) result = result.toFixed(2);
      this.test.result = "" + result;
    },
    checkAndCalculate() {
      window.ha.log("Calculating using formula...");
      // prep the scope
      let scope = {};

      if (this.peers) {
        // build scope
        for (let peer of this.peers) {
          if (peer.code && peer.result) {
            scope[peer.code] = peer.result || "";
          }
        }

        for (let peer of this.peers) {
          if (peer.formula != undefined) {
            // compute the value
            window.ha.log(peer.formula);
            window.ha.log(scope);
            try {
              let result = evaluate(peer.formula, scope);
              if (!Number.isInteger(result)) result = result.toFixed(2);
              window.ha.log("Result : " + result);

              // check if old computed and newly computed is different, if yes, then replace, else continue.
              if (peer.computedResult == result) {
                continue;
              } else {
                peer.result = "" + result;
                peer.computedResult = "" + result;
                if (peer.code) {
                  scope[peer.code] = result;
                  window.ha.log(
                    "Updating scope for " +
                      peer.code +
                      " to " +
                      scope[peer.code]
                  );
                }
              }
            } catch (err) {
              window.ha.log(err);
            }
          }
        }
      }
    },
    checkShortcut(event) {
      // window.ha.log("Checking shortcut:");
      // window.ha.log(event);
      if (!isNaN(event.key) && event.ctrlKey == true) {
        // window.ha.log("Clicked: Ctrl + " + event.key);
        // window.ha.log("Shortcut Value: " + this.shortcuts[event.key]);
        this.test.result = "" + this.shortcuts[event.key];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ha-test-row {
  min-height: 3rem;
}
.ha-test-row.haMobile {
  border-bottom: rgba(0, 0, 0, 0.6) solid 1px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}
.row-level-1 {
  padding-left: 0rem;
  width: calc(100% - 0rem);
}
.row-level-2 {
  padding-left: 0rem;
  width: calc(100% - 0rem);
}
.row-level-3 {
  padding-left: 2rem;
  width: calc(100% - 2rem);
}
.row-level-4 {
  padding-left: 4rem;
  width: calc(100% - 4rem);
}
.haMobile .row-level-1,
.haMobile .row-level-2,
.haMobile .row-level-3,
.haMobile .row-level-4 {
  padding-left: 0rem;
}
.highlight {
  font-weight: bold;
}
.ha-test-group {
  font-weight: bold;
}

.print {
  display: none;
}
.ha-fos {
  color: #272727;
  background: #d6d6d6;
  text-align: center;
  height: 25px;
}

@media print {
  .noprint {
    display: none;
  }

  .ha-test-row.haMobile {
    border-bottom: none;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
  }

  .ha-fos {
    color: #272727;
    background: #d6d6d6;
    text-align: center;
    height: 25px;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .print {
    display: block !important;
  }
  .ha-test-row {
    min-height: 1rem;
  }
  .abnormal {
    font-weight: bold;
  }

  .highlight {
    font-weight: normal;
  }

  .refRangeLabel {
    display: none;
  }

  .ha-test-row.haMobile {
    border-bottom: none;
  }
}
</style>


<style>
.abnormal {
  font-weight: bold;
}
.abnormal .v-input__slot {
  font-weight: bold;
  border: 2px solid #e57373;
}
.formula .v-input__slot {
  border: 2px solid #ba68c8;
}

@media print {
  .ha-fos {
    color: #272727;
    background: #d6d6d6;
    text-align: center;
    height: 25px;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
}
</style>
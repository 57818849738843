function getAge(dateObj, metric) {
  if (!dateObj) return 0;
  // days in a months
  function daysInMonth(iMonth, iYear) {
    // took from: https://dzone.com/articles/determining-number-days-month

    // Essentially, writing some code to determine the number of days in a given month of a given year with javascript is not the worlds most difficult task. It is the type of exercise that one would expect to be given as a newbie developer during a lab or lecture. The solution normally involves determining if the month is February, an month with 30 days or a month with 31 days, then (if February) checking if the year is a leap year. All these tests add up, however, and add several lines of code to your .js file. They are also unnecessary!
    // Apparently, the javascript Date function allows you to overflow the day number parameter that you pass, creating a date in the next month. Deliberately overflowing the day parameter and checking how far the resulting date overlaps into the next month is a quick way to tell how many days there were in the queried month. Here is a function that does this:
    // How does this function work? It is quite simple. When the Date() function is given a day number that is greater than the number of days in the given month of the given year, it wraps the date into the next month. The getDate() function returns the day of the month, starting from the beginning of the month that the date is in. So, day 32 of March is considered to be day 1 of April. Subtracting 1 from 32 gives the correct number of days in March!

    return 32 - new Date(iYear, iMonth, 32).getDate();
  }

  if (parseInt(dateObj)) {
    dateObj = new Date(parseInt(dateObj));

    var now = new Date(); //Todays Date

    var nowDay = now.getDate();
    var nowMonth = now.getMonth() + 1; //jan=0 so month+1
    var nowYear = now.getFullYear();

    var dobMonth = dateObj.getMonth() + 1; //jan=0 so month+1
    var dobDay = dateObj.getDate();
    var dobYear = dateObj.getFullYear();

    var ageyear = nowYear - dobYear;
    var agemonth = nowMonth - dobMonth;
    var ageday = nowDay - dobDay;

    if (nowDay < dobDay) {
      agemonth--;
      //var days = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();  get current months days
      //why is it 30 ? will it work for all months  ?
      // ageday = 30 + ageday;

      // add number of days of previous month
      ageday = daysInMonth(nowMonth - 2, nowYear) + ageday;
    }
    if (agemonth < 0) {
      ageyear--;
      agemonth = 12 + agemonth;
    }

    if (metric == "YEAR") return ageyear;
    else if (metric == "MONTH") return agemonth;
    else return ageday;
  }
}
function computeAge(dob) {
  if (!dob || dob == "0") return "";
  dob = dob.getTime();
  var ageYears = getAge(dob, "YEAR");
  var ageMonths = getAge(dob, "MONTH");
  var ageDays = getAge(dob, "DAY");
  if (ageYears <= 0) {
    var age = "";
    if (ageMonths > 0) {
      age = ageMonths + " Months";
      if (ageDays > 0) {
        age += ", " + ageDays + " Days";
      }
    } else if (ageDays > 0) {
      age = ageDays + " Days";
    }
    return age;
  } else if (ageYears <= 5 && ageMonths > 0) {
    return ageYears + " Years, " + ageMonths + " Months";
  } else {
    return ageYears + " Years";
  }
}

let UtilSrv = {
  computeAge: computeAge,
};

export { UtilSrv };

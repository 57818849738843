<template>
  <div>
    <Header
      class="noprint"
      appName="Patient Access: Online Lab Reports"
    ></Header>
    <div class="mx-auto mt-5" style="max-width: 30rem">
      <v-card class="pa-5 justify-space-around" v-if="orgArr.length == 0">
        <h2>Welcome to Health Amaze!</h2>
        <br />
        <v-select
          v-model="country"
          :items="countryArr"
          label="Country"
          outlined
          dense
          hide-details
        ></v-select>
        <br />
        <v-text-field
          v-model="phone"
          type="tel"
          dense
          hide-details
          style="width: 20rem"
          :prefix="country == 'India' ? '+91' : '+63'"
          label="Phone Number"
        ></v-text-field
        ><br />
        <v-card-actions>
          <v-btn dark color="primary" @click="sendOTP"> Login </v-btn>
        </v-card-actions>
      </v-card>

      <!-- OTP -->
      <v-dialog v-model="OTPDialog" max-width="800">
        <v-card>
          <v-card-title class="headline">Enter OTP</v-card-title>

          <v-otp-input length="4" v-model="OTPValue" class="mx-4"></v-otp-input>

          <v-alert dense outlined type="error" v-show="OTPErrorMsg">
            Incorrect OTP.
          </v-alert>
          <v-card-actions>
            <v-btn dark color="primary" @click="validateOTP()"> Ok </v-btn>
            <v-btn
              text
              @click="
                OTPDialog = false;
                OTPErrorMsg = false;
              "
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  name: "Home",
  props: {},
  components: {
    Header,
  },
  created() {
    // this.db = db.collection("Org").doc(this.$route.params.orgId);
  },
  data() {
    return {
      countryArr: ["India", "Philippine"],
      country: "India",
      phone: "",
      OTPFromServer: 0,
      OTPDialog: false,
      OTPErrorMsg: "",
      OTPValue: "",
      orgArr: [],
    };
  },
  methods: {
    sendOTP() {
      console.log("Sending OTP");
      let phonePrefix = this.country == "India" ? "+91" : "+63";
      var sendOTP = window.firebase
        .functions()
        .httpsCallable("patientLoginOTP-patientLoginOTP");
      sendOTP({
        phoneNumber: phonePrefix + this.phone,
      })
        .then((result) => {
          if (result.data) {
            window.ha.log("OTP: " + result.data);
            this.OTPFromServer = result.data;
            this.OTPDialog = true;
          }
        })
        .catch((err) => {
          window.ha.error(err);
        });
      // this.OTPDialog = true;
    },
    validateOTP() {
      if (btoa(this.OTPValue) == this.OTPFromServer) {
        this.OTPErrorMsg = false;
        this.OTPDialog = false;
        // this.loadReport();
        this.$router.push("LabList");
        window.localStorage.setItem("phone", this.phone);
      } else {
        this.OTPErrorMsg = true;
      }
    },
  },
};
</script>

<style>
#app {
  background: #b3e5fc;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ha-tile {
  background: white;
  color: black;
  display: inline-block;
  margin: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px !important;
  text-align: center;
  transition: transform 0.2s;
}
.ha-tile:hover {
  /* background: white;
  transform: scale(1.05);
  box-shadow: #cfcfcf 1px 1px 10px; */
  border-color: #f4f4f4 !important;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  transition: border-color 0.167s linear, box-shadow 0.167s linear;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
